import React from 'react';
import './footer.css';

// Icons (import from a library like react-icons or use your own SVGs)
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer: React.FC = () => {
  return (
    <footer className={"footer"}>
      {/* Logo and Description Section */}
      <div className={"logoSection"}>
        <img src={'logo.png'} alt="School Logo" className={"logo"} />
        <p className={"description"}>
          Our institute is dedicated to fostering educational excellence and personal growth in a supportive environment.
        </p>
      </div>

      {/* Navigation Links */}
      <div className={"linksSection"}>
        <h4>Quick Links</h4>
        <ul className={"navLinks"}>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About Us</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/admissions">Admissions</a></li>
          <li><a href="/events">Events</a></li>
        </ul>
      </div>

      {/* Social Media Links */}
      <div className={"socialSection"}>
        <h4>Follow Us</h4>
        <div className={"socialIcons"}>
          <a href="https://facebook.com/school" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://twitter.com/school" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </a>
          <a href="https://instagram.com/school" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://linkedin.com/school" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
        </div>
      </div>

      {/* Contact Info */}
      <div className={"contactSection"}>
        <h4>Contact Us</h4>
        <address>
          Kuttikkonam, Kunnicode P.O, Kollam, kerala<br />
          Phone: (+91) 8547597760, (+91) 7736689760<br />
          Email: <a href="mailto:info@school.edu">nationalttikuttikkonam@gmail.com</a>
        </address>
      </div>

      {/* Copyright */}
      <div className={"copyright"}>
        <p>&copy; {new Date().getFullYear()} National I.T.E. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
