import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Card.css'

type props = {
    button1?: string,
    button2?: string,
    desc: string,
    title: string,
    button1OnClick?: any,
    button2OnClick?: any,
    img: string
}

export default function MediaCard({title, desc, button1, button2, button1OnClick, button2OnClick, img}: props) {
  return (
    <Card sx={{ maxWidth: 345}} style={{minHeight: '400px', width: 'fit-content'}} elevation={3}>
      <div style={{position: 'relative', height: '200px'}}>
        <CardMedia
          image={img}
          title={title}
          style={{height: '100%', cursor: 'pointer'}}
          className='cardMedia'
        />
        <Typography gutterBottom variant="h5" component="div" 
          style={{position: "absolute", bottom: "0", width: '100%', color: 'white', padding: '0.5rem 1rem', backgroundColor: "#00000036", fontSize: "20px", textAlign: "left"}}
        >
          {title}
        </Typography>
      </div>
      <CardContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} textAlign={"left"}>
          {desc}
        </Typography>
      </CardContent>
      <CardActions>
        {button1 && <Button size="small" onClick={button1OnClick()} style={{color: '#FFD700'}}>{button1}</Button>}
        {button2 && <Button size="small" onClick={button2OnClick()}>{button2}</Button>}
      </CardActions>
    </Card>
  );
}
