import React from 'react'
import './Courses.css'
import Footer from './Footer'
import Box from '@mui/material/Box';
import BasicTable from './FeeStructure';

function Courses() {

    function createData(
        name: string,
        desc: string,
        fee: string
    ) {
    return { name, fee, desc };
    }

  return (
    <section className='course' id='courses' style={{paddingTop: '3rem'}}>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
            <h2 className="mainTitle">Courses We Offer</h2>
        </div>
        <div className="mainContent">
            <div className="first">
                <h2>Diploma in Elemantary Education (D.El.Ed)</h2>
                <h4>Two year regular programme</h4>
                <p>D.El.Ed is a Diploma course in Elementary Education. It is a 2 year full time diploma course to train teachers for the primary level and can be done with classroom training or distance learning. It is a 4 semester course to prepare students to work in primary/ upper primary schools in different states . The design of the course is such that it includes practical training and internships along with classroom training. It includes principles of child development, education practice, and the role of the teacher in grooming the students.</p>
            </div>
            <div className="second">

            </div>
            <div className="third">
                <div className='tableWrapper'>
                    <BasicTable
                        rows={
                            [
                                createData('Tuition Fee', '(Fee exemption for SC/ST, OEC, OBC(H) Students)', "30,000 Rs/year")
                            ]
                        }
                        title="Student Fees"
                    />
                    <BasicTable
                        rows={
                            [
                                createData('Tuition Fee', '', "35,000 Rs/year"),
                                createData('Admission & application Fee', '', "200 Rs"),
                            ]
                        }
                        title="Management Fees"
                    />
                </div>
            </div>
        </div>
        {/* <Footer/> */}
    </section>
  )
}

export default Courses