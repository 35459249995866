import React from 'react'
import './Contact.css'
import Footer from './Footer'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import GoogleMapComponent from './Map';

function Contact() {
  return (
    <section className='contact' id='contact'>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
            <h2 className="mainTitle">Contact Us</h2>
        </div>
        <div className="mainContent">
            <div className="left" style={{width: "100%"}}>
                {/* <GoogleMapComponent/> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d749644.7113843772!2d76.56885812777918!3d8.881170056733657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0673139032ff21%3A0x39022904f1f06489!2sKunnicode%20-%20Pathanapuram%20Rd%2C%20Kerala!5e0!3m2!1sen!2sin!4v1730867103362!5m2!1sen!2sin" width="100%" height="450" style={{border: 0}} allowFullScreen={true} loading="lazy" referrerPolicy={"no-referrer-when-downgrade"}></iframe>
            </div>
            <form action="" className='form'>
                <Box
                    component="form"
                    sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                    style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
                    noValidate
                    autoComplete="off"
                >
                    <div className='nameEmailDiv' style={{width: "100%"}}>
                        <TextField id="outlined-basic" label="Name" variant="outlined" style={{flex: 1}}/>
                        <TextField id="outlined-basic" label="Email" variant="outlined" style={{flex: 1}}/>
                    </div>
                    <TextField id="outlined-basic" label="Phone Number" variant="outlined" style={{width: "100%"}} />
                    <TextField
                        id="outlined-multiline-static"
                        label="Multiline"
                        multiline
                        rows={4}
                        style={{width: "100%"}}
                    />
                    <div className='buttonWrapper' style={{width: "100%"}}>
                        <Button variant="contained" size='large'>Submit</Button>
                    </div>
                </Box>
            </form>
        </div>
        {/* <Footer/> */}
    </section>
  )
}

export default Contact