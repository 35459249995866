// src/App.tsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import './App.css';
import DrawerAppBar from './MuiNavbar';
import Contact from './Contact';
import Courses from './Courses';

const App: React.FC = () => {
    const [scrolled, setScrolled] = useState<boolean>(false);
    const hideNavbar: string[] = []
    const location = useLocation();

    const handleScroll = () => {
        const offset = window.scrollY;
        setScrolled(offset > 50);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={scrolled ? 'App scrolled' : 'App'}>
            {/* <Navbar /> */}
            {
                !hideNavbar.includes(location.pathname) &&
                <DrawerAppBar/>
            }
            <Routes>
                <Route path="/" element={<Home page="home"/>} />
                <Route path="/about" element={<Home page="about"/>} />
                <Route path="/management" element={<Home page="management"/>} />
                <Route path="/instructors" element={<Home page="instructors"/>} />
                <Route path="/newsAndEvents" element={<Home page="newsAndEvents"/>} />
                <Route path="/contact" element={<Home page="contact"/>} />
                <Route path="/courses" element={<Home page="courses"/>} />
            </Routes>
        </div>
    );
};

export default App;
