// src/Home.tsx
import React, { useEffect, useState } from 'react';
import './Home.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MediaCard from './Card';
import Footer from './Footer';
import CustomCarousel from './ImageSlider';
import Courses from './Courses';
import Contact from './Contact';
import { useNavigate } from 'react-router-dom';

type props = {
    page: string
}

const Home = ({page}: props) => {
    const nav = useNavigate();
    const [management, setManagement] = useState([
        {
            img: 'sssingh.png',
            name: "Mr. S S Singh",
            designation: "Principal"
        }
    ])
    const [instructors, setInstructors] = useState([
        {
            img: 'midhunpk.png',
            name: "Mr. Midhun P.K",
            designation: "Teacher Educator"
        },
        {
            img: 'malinip.png',
            name: "Malini P",
            designation: "Teacher Educator"
        },
        {
            img: 'resmir.png',
            name: "Resmi R",
            designation: "Teacher Educator"
        },
        {
            img: 'linidasm.png',
            name: "Linidas M",
            designation: "Teacher Educator"
        },
        {
            img: 'mayshelfas.png',
            name: "Mayshelfa S",
            designation: "Teacher Educator"
        },
    ])
    const [news, setNews] = useState([
        {
            button1: 'Learn More',
            desc: 'Marks the beginning of an exciting and transformative journey for future educators. This event celebrates the new cohort entering the teaching profession, bringing together students, faculty, and education professionals to inspire and welcome our newest members.',
            img: './event2.jpg',
            title: 'Batch Inaugration',
            id: 'learnMore'
        },
        {
            button1: 'Learn More',
            desc: 'Fostering a supportive learning environment for both the young learners at TVTM and the teacher candidates at National ITE. By combining expertise, resources, and a shared vision, this collaboration hopes to contribute positively to the local education community and inspire a new generation of skilled, motivated educators.',
            img: './event1.jpg',
            title: 'Main Event',
            id: 'learnMore'
        }
    ]);

    const images = [
        {
          imgURL:
            "./image1.jpg",
          imgAlt: "img-1"
        },
        {
          imgURL:
            "./image2.jpg",
          imgAlt: "img-2"
        },
        {
          imgURL:
            "./image3.jpg",
          imgAlt: "img-3"
        },
        {
          imgURL:
            "./image4.png",
          imgAlt: "img-4"
        },
        {
            imgURL:
              "./image5.jpg",
            imgAlt: "img-3"
        },
        {
            imgURL:
              "./image6.jpg",
            imgAlt: "img-3"
        },
    ]

    const smoothScrollBy = (offset: number, duration: number = 500) => {
        const start = window.scrollY;
        const end = start + offset;
        const change = offset;
        const startTime = performance.now();
      
        const animateScroll = (currentTime: number) => {
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
      
          // Ease-in-out function for smoothness
          const easeInOut = progress < 0.5
            ? 2 * progress * progress
            : -1 + (4 - 2 * progress) * progress;
      
          window.scrollTo(0, start + change * easeInOut);
      
          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };
      
        requestAnimationFrame(animateScroll);
      };
      
      const scrollToSectionWithOffset = (id: string, offset: number = -50) => {
        const section = document.getElementById(id);
        if (section) {
          const targetPosition = section.getBoundingClientRect().top + window.scrollY + offset;
          const scrollOffset = targetPosition - window.scrollY;
          smoothScrollBy(scrollOffset);
        }
      };
      

    useEffect(()=>{
        scrollToSectionWithOffset(page);
    }, [page])
    return (
        <div className="home" id='home'>
            <section className="welcome-section">
                <div className="welcome-text">
                    <span>Welcome to National I.T.E Kuttikkonam</span>
                    <p className='poppins-medium'>Where learning is a journey.</p>
                    <div className="buttons" style={{marginTop: "10px"}}>
                        <Button variant="contained" size='large'
                            onClick={()=>{
                                // const url = `${window.location.origin}/courses`;
                                // window.open(url);
                                nav("/courses")
                            }}
                            style={{backgroundColor: "#FFD700"}}
                        >Courses</Button>
                        <Button variant="outlined" size='large' onClick={()=>{
                                // const url = `${window.location.origin}/contact`;
                                // window.open(url);
                                nav("/contact")
                            }}
                            style={{color: "#FFD700", borderColor: "#FFD700"}}
                        >Contact Us</Button>
                    </div>
                </div>
            </section>

            {/* Add more sections as needed */}
            <section className="info-section aboutSection" id='about'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: "15px", padding: "0 10rem"}}>
                    <h2 className="mainTitle">About Us</h2>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                        textAlign={"left"}
                    >
                        <span style={{fontSize: "28px", color: '#FFD700'}}>Over 15 years of Excellence</span><br></br><br></br>
                        <p>
                            The National Institute of Teacher Education, located in Kollam, was established in 2005 with a vision to provide top-quality education and training for aspiring teachers. As an unaided institution, it operates independently yet is fully recognized by the National Council for Teacher Education (NCTE) and holds affiliation with the State Educational Research and Training (SERT) Council, Kerala. Each academic year, the institution admits a total of 50 students, with seats allocated between 25 merit-based and 25 management-based admissions, ensuring a balanced intake of students. The institute is equipped with all the facilities mandated by NCTE, aiming to foster academic excellence, practical skills, and the holistic development of students across all levels. This comprehensive infrastructure supports the enhancement of student competencies, ensuring they are well-prepared to meet the demands of the teaching profession.
                        </p>
                    </Typography>
                </div>
            </section>
            <section className='sectionAd' style={{marginTop: '20px'}}>
                <span className='poppins-medium'>Get the right course for a better future</span>
                <Button variant="outlined" size='large'onClick={()=>{
                            // const url = `${window.location.origin}/contact`;
                            // window.open(url, '_blank');
                            nav("/contact")
                    }}
                    style={{color: "#8B0000", borderColor: "#8B0000"}}
                >Contact Us</Button>
            </section>
            <Courses/>
            <section className="info-section aboutSection" id='management'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: "15px", padding: "0 10rem"}}>
                    <h2 className="mainTitle">Our Management</h2>
                    <div className="imgCardWrapper">
                        {
                            management.map(x=> {
                                return (
                                    <Paper elevation={3} className='managementCard' style={{borderRadius: '20px', margin: "10px"}}>
                                        <img src={x.img} alt="" className='managementImg' style={{borderRadius: '20px'}}/>
                                        <span style={{display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
                                            <span className='poppins-medium' style={{fontSize: '20px'}}>{x.name}</span>
                                            <span className='poppins-light' style={{fontSize: '15px', color: 'gray'}}>{x.designation}</span>
                                        </span>
                                    </Paper>
                                    // <div className='managementWrapper' style={{display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
                                    // </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="info-section" style={{backgroundColor: "#fafafa"}} id='instructors'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: "15px", padding: "0 10rem"}}>
                    <h2 className="mainTitle">Our Instructors</h2>
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                        textAlign={"left"}
                        fontSize={"15px"}
                        className='poppins-light'
                    >
                        <p>
                            At National I.T.E Kuttikkonam, our instructors are dedicated professionals committed to fostering a supportive and engaging learning environment. With diverse backgrounds and expertise, they inspire students to reach their full potential through personalized education and innovative teaching methods.
                        </p>
                    </Typography>
                    <div className="imgCardWrapper">
                        {
                            instructors.map(x=> {
                                return (
                                    <div className='managementWrapper' style={{display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
                                        <Paper elevation={3} className='managementCard' style={{borderRadius: '20px'}}>
                                            <img src={x.img} alt="" className='managementImg' style={{borderRadius: '20px'}}/>
                                        </Paper>
                                        <span style={{display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
                                            <span className='poppins-medium' style={{fontSize: '20px'}}>{x.name}</span>
                                            <span className='poppins-light' style={{fontSize: '15px', color: 'gray'}}>{x.designation}</span>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="info-section" id='newsAndEvents'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: "15px", padding: "0 10rem"}}>
                    <h2 className="mainTitle">News & Events</h2>
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        textAlign={"left"}
                        fontSize={"15px"}
                        className='poppins-light'
                    >
                        <p>
                            Stay up-to-date with the latest happenings at our institution! The News and Events section provides timely updates on important announcements, academic achievements, workshops, seminars, and cultural events.
                        </p>
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                width: 'auto',
                                height: 250,
                            },
                            gap: '20px',
                            justifyContent: "center",
                            width: "100%",
                            padding: "1rem 0"
                        }}
                    >
                        {
                            news.map(x=>(
                                <MediaCard
                                    button1={x.button1}
                                    button1OnClick={()=>{}}
                                    desc={x.desc}
                                    img={x.img}
                                    title={x.title}
                                />
                            ))
                        }
                    </Box>
                </div>
            </section>
            <section className="info-section" id='Gallery'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px", padding: "0 10rem"}}>
                    <h2 className="mainTitle">Gallery</h2>
                    <CustomCarousel>
                        {images.map((image: any, index: number) => {
                            return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
                        })}
                    </CustomCarousel>
                </div>
            </section>
            <Contact/>
            <Footer/>
        </div>
    );
};

export default Home;
